import * as React from 'react';
import EmailViewStyle from './EmailViewStyle';
import BaseComponent from '../../../../../../abstracts/BaseComponent';
import EmailViewProps from './EmailViewProps';
import { Spring } from 'react-spring';
import Lottie from 'lottie-react-web';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import DateHelper from 'src/helpers/DateHelper';

export default class EmailView extends BaseComponent<EmailViewProps, any>
{
    /* ATTRIBUTES */
    style = new EmailViewStyle();

    /* CONSTRUCTOR */
    constructor(props: any) {
        super(props);

        // Initial state
        this.state =
        {
            open: this.props.onClick !== undefined
        };

        // Bindings
        this.mapColors = this.mapColors.bind(this);
        this.renderBadge = this.renderBadge.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handleHovered = this.handleHovered.bind(this);
    }

    /* HANDLERS */
    async handleSend() {
        const { onClick } = this.props;

        if (onClick && !this.state.isBusy) {
            onClick(this.props.communication);
            this.setState({ isBusy: true });
        }
    }

    handleHovered() {
        this.setState((previousState) => {
            return { sendHovered: !previousState.sendHovered }
        })
    }

    /* METHODS */
    mapColors() {
        const { targetType } = this.props.communication;

        switch (`${targetType}`) {
            // WORKER
            case "0":
                return { backgroundColor: '#FDB56D' };

            // COMPANY
            case "1":
                return { backgroundColor: '#9FD6E5' };

            // DOCTOR
            default:
                return { backgroundColor: '#85D8C8' };
        }
    }

    /* RENDER */
    render() {
        const fromAnimation = { marginLeft: window.innerWidth - 517 - 80, scale: 0 };
        const toAnimation = { marginLeft: 0, scale: 1 };

        return (
            <Spring from={fromAnimation} to={toAnimation} delay={this.props.delay}>
                {
                    (animatedStyle: any) => {
                        return (
                            <LocalizationContext.Consumer>
                                {({ translate }) => (
                                    <div key={this.props.communication.id} style={{ ...this.style.basicLogContainer, transform: `translate(${animatedStyle.marginLeft}px, ${0}px)` }}>
                                        <div style={this.style.basicLog}>

                                            {/* STAMP RENDERING */}
                                            {this.renderStamp(this.props.log)}

                                            <div style={{ ...this.style.commentLogText, ...this.mapColors(), borderTopLeftRadius: 4, borderTopRightRadius: 4, height: 38, borderBottomLeftRadius: this.state.open ? 0 : 4, borderBottomRightRadius: this.state.open ? 0 : 4 }}>
                                                <div style={{ float: 'left' }}>
                                                    {`Sent : ${translate(this.props.communication.title)}`}
                                                </div>
                                                {
                                                    !this.props.onClick && (
                                                        <div onClick={() => this.setState({ open: !this.state.open })} style={{ float: 'right', cursor: 'pointer' }}>
                                                            {this.state.open ? '-' : '+'}
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            {/* LOG RENDERING */}
                                            <div style={{ ...this.style.logText, ...this.mapColors(), display: this.state.open ? '' : 'none' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ ...this.style.logTitleText, marginTop: 5, width: 85 }}>
                                                        Recipient :
                                                    </div>
                                                    <div style={{ ...this.style.logBodyText, width: '100%', fontSize: 12, padding: 6, maxWidth: 'calc(100% - 75px)' }}>
                                                        {this.props.communication.recipient}
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ ...this.style.logTitleText, marginTop: 5, width: 85 }}>
                                                        Subject :
                                                    </div>
                                                    <div style={{ ...this.style.logBodyText, width: '100%', fontSize: 12, padding: 6, maxWidth: 'calc(100% - 75px)' }}>
                                                        {this.props.communication.subject}
                                                    </div>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ ...this.style.logTitleText, marginTop: 5, width: 85 }}>
                                                        Message :
                                                    </div>
                                                    <div style={{ ...this.style.logBodyText, width: '100%', fontSize: 12, padding: 6, marginBottom: 0, overflowWrap: "break-word", maxWidth: 'calc(100% - 75px)' }}>

                                                        <div dangerouslySetInnerHTML={{ __html: this.props.communication.message }} style={{ WebkitUserSelect: 'text' }} />

                                                        {this.props.onClick && (
                                                            <div onMouseEnter={this.handleHovered} onMouseLeave={this.handleHovered} onDoubleClick={this.handleSend} style={{ cursor: 'pointer', position: 'relative', ...this.mapColors(), height: 34, width: 34, borderRadius: 17, float: 'right', transform: 'translate(10px, 10px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                {!this.state.isBusy ?
                                                                    (
                                                                        <img src={`/assets/button_send_neutral${this.state.sendHovered ? '_hovered' : ''}.svg`} style={{ height: 30, width: 30 }} />
                                                                    )
                                                                    :
                                                                    (
                                                                        <Lottie options={{ animationData: require('../assets/loading.json') }} />
                                                                    )
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        {/* BADGE RENDERING */}
                                        {this.renderBadge(this.props.log)}
                                    </div>
                                )}
                            </LocalizationContext.Consumer>
                        );
                    }
                }
            </Spring>
        )
    }

    renderStamp(log: any, companyLog?: any, userLog?: any) {
        if (log) {
            if (log.user) {
                return (
                    <span style={this.style.basicStampRight}>
                        {`${log.user.firstName} ${log.user.lastName} (Medicheck) ${DateHelper.formatDate(log.creationDate)}`}
                    </span>
                );
            }
        }

        if (!log) {
            return (
                <span style={this.style.basicStampRight}>
                    {`...`}
                </span>
            )
        }

        if (userLog && companyLog) {
            return (
                <span style={this.style.basicStampRight}>
                    {`${userLog} (${companyLog}) ${DateHelper.formatDate(log.creationDate)}`}
                </span>
            );
        }

        return (
            <span style={this.style.basicStampRight}>
                {`Valou System (Medicheck) ${DateHelper.formatDate(log.creationDate)}`}
            </span>
        );
    }

    renderBadge(log?: any) {
        if (log) {

            let user = log.user;

            if (!user) {
                user = {
                    firstName: "Valou",
                    lastName: "System"
                }
            }

            return (
                <div style={this.style.badgeContainer}>
                    <span style={this.style.badgeText}>
                        {`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}
                    </span>
                </div>
            );
        }

        return (
            <div onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })} onClick={() => this.props.handleDeleteCommunication(this.props.communication)} style={{ ...this.style.badgeContainer, ...this.mapColors(), cursor: 'pointer', padding: this.state.hovered ? 0 : 10 }}>
                {this.state.hovered ?
                    (
                        <img src="/assets/button_delete_certif_hovered.png" style={{ height: 40, width: 40 }} />

                    )
                    :
                    (
                        <img src="/assets/icon_user.svg" style={this.style.userIcon} />
                    )
                }
            </div>
        );
    }
}