import { useEffect } from 'react';
import { App } from 'src/app';
import { useUser } from 'src/components/userContext/UserContext';
import MeService from 'src/services/MeService';

const LoginView = () => {
  // Attributes
  const { updateToken, updateUser } = useUser();

  // Effects
  useEffect(() => {
    window.onmessage = async function (event) {
      if (event.data?.name == 'token') {
        updateToken(
          event.data?.data.accessToken,
          event.data?.data.refreshToken
        );
        const user = await new MeService().get();
        updateUser(user);
        App.navigate('/dashboard', {});
      }
    };
  }, []);

  return (
    <iframe
      title='login-view'
      src={`https://apps-73qybaztda-ew.a.run.app/auth/sign-in?shouldSignOut=${
        App.history.location.state?.shouldSignOut ? 1 : 0
      }`}
      style={{
        width: '100%',
        height: '100vh',
        padding: 0,
        margin: 0,
        border: '0px',
      }}
    />
  );
};

export default LoginView;
